import React, { useEffect } from "react";
import Footer from "../Templete/Footer";
import Header from "../Templete/Header";

// import Testimonials from "../Home/Testimonials";
// import GetInTouch from "../Home/GetInTouch";

const RefundPolicy = () => {
  useEffect(() => {
    document.title = "Privacy Policy - Recharge POS";
  }, []);

  return (
    <React.Fragment>
      <div class="page_wrapper">
        <Header headerType="style3" />

        <div className="inner-hero-warp">
          <div className="container">
            <div className="inner-hero-content">
              <h1>Privacy Policy</h1>
              <p></p>
            </div>
          </div>
        </div>

        <div class="terms-conditions-area pt-100 pb-75">
          <div class="container">
            <div class="terms-conditions">
              <div class="conditions-content">
                <div className="info">
                  <div className="pb-3">
                    <h4>Privacy Policy for Recharge POS</h4>
                  </div>
                  <h5>Introduction</h5>
                  <p class="text-slate-400">
                    <b>Recharge POS</b> (“us”, “we”, or “our”) operates{" "}
                    <b>rechargepos.com</b> and Recharge India mobile app
                    available on google play store (hereinafter referred to as{" "}
                    <b>“Service”</b>).
                    <p class="text-slate-400">
                      Our Privacy Policy governs your visit to{" "}
                      <b>rechargepos.com</b>, and explains how we collect,
                      safeguard and disclose information that results from your
                      use of our Service.
                    </p>
                    <p class="text-slate-400">
                      We use your data to provide and improve Service. By using
                      Service, you agree to the collection and use of
                      information in accordance with this policy. Unless
                      otherwise defined in this Privacy Policy, the terms used
                      in this Privacy Policy have the same meanings as in our
                      Terms and Conditions.
                    </p>
                    <p class="text-slate-400">
                      Our Terms and Conditions (<b>“Terms”</b>) govern all use
                      of our Service and together with the Privacy Policy
                      constitutes your agreement with us (<b>“agreement”</b>).
                    </p>
                  </p>
                  <h5 class="text-xl font-semibold mb-4 mt-8">Definitions :</h5>
                  <p class="text-slate-400">
                    This privacy notices describes how we might use your
                    information if you:
                  </p>
                  <ul class="text-slate-400">
                    <p>0.1. Visit our website at rechargepos.com</p>
                    <p>
                      0.2 Download and use our mobile application - Recharge
                      India
                    </p>
                    <p>
                      0.3 Engage with is in other related ways - including any
                      sales, marketing or events
                    </p>
                  </ul>
                  <p class="text-slate-400">
                    <b>"WEBSITE",</b> we are referring to any website of ours
                    that references or link to this policy
                  </p>
                  <p class="text-slate-400">
                    <b>"APP",</b> we are referring to any application of ours
                    that references or links to this policy, including any
                    listed above
                  </p>
                  <p class="text-slate-400">
                    <b>"SERVICE",</b> we are referring to our website, app, and
                    other related services, including any sales, marketing or
                    events
                  </p>
                  <p class="text-slate-400">
                    <b>PERSONAL DATA</b> means data about a living individual
                    who can be identified from those data (or from those and
                    other information either in our possession or likely to come
                    into our possession).
                  </p>
                  <p class="text-slate-400">
                    <b>USAGE DATA</b> is data collected automatically either
                    generated by the use of Service or from Service
                    infrastructure itself (for example, the duration of a page
                    visit).
                  </p>
                  <p class="text-slate-400">
                    <b>COOKIES</b> are small files stored on your device
                    (computer or mobile device).
                  </p>
                  <p class="text-slate-400">
                    <b>DATA CONTROLLER</b> means a natural or legal person who
                    (either alone or jointly or in common with other persons)
                    determines the purposes for which and the manner in which
                    any personal data are, or are to be, processed. For the
                    purpose of this Privacy Policy, we are a Data Controller of
                    your data.
                  </p>
                  <p class="text-slate-400">
                    <b>DATA PROCESSORS (OR SERVICE PROVIDERS)</b> means any
                    natural or legal person who processes the data on behalf of
                    the Data Controller. We may use the services of various
                    Service Providers in order to process your data more
                    effectively.
                  </p>{" "}
                  <p class="text-slate-400">
                    <b>DATA SUBJECT</b> is any living individual who is the
                    subject of Personal Data.
                  </p>
                  <p class="text-slate-400">
                    <b>THE USER</b> is the individual using our Service. The
                    User corresponds to the Data Subject, who is the subject of
                    Personal Data.
                  </p>
                  <h5 class="text-xl font-semibold mb-4 mt-8">
                    Information Collection and Use :
                  </h5>
                  <p class="text-slate-400">
                    We collect several different types of information for
                    various purposes to provide and improve our Service to you.
                  </p>
                  <h5 class="text-xl font-semibold mb-4 mt-8">
                    Types of Data Collected :
                  </h5>
                  <p class="text-slate-400">
                    <b>Personal Data</b>
                  </p>
                  <p class="text-slate-400">
                    While using our Service, we may ask you to provide us with
                    certain personally identifiable information that can be used
                    to contact or identify you (<b>“Personal Data”</b>).
                    Personally identifiable information may include, but is not
                    limited to:
                  </p>
                  <p class="text-slate-400">
                    0.1. Full name, age and date of birth
                  </p>
                  <p class="text-slate-400">0.2. PAN</p>
                  <p class="text-slate-400">
                    0.3. Username, user id & password
                  </p>
                  <p class="text-slate-400">0.4. Email address</p>
                  <p class="text-slate-400">0.5. Mobile number</p>
                  <p class="text-slate-400">
                    0.7. Address, Country, State, District, City, Pincode
                  </p>
                  <p class="text-slate-400">0.8. IP address</p>
                  <p class="text-slate-400">
                    0.9. Credit/debit card/payment instrument details
                  </p>
                  <p class="text-slate-400">
                    0.10 User’s feedback, queries, e-mails, letters, suggestions
                  </p>
                  <p class="text-slate-400">
                    We may use your Personal Data to contact you with
                    newsletters, marketing or promotional materials and other
                    information that may be of interest to you. You may opt out
                    of receiving any, or all, of these communications from us by
                    following the unsubscribe link.
                  </p>
                  <p class="text-slate-400">
                    <b>Usage Data</b>
                  </p>
                  <p class="text-slate-400">
                    We may also collect information that your browser sends
                    whenever you visit our Service or when you access Service by
                    or through any device (<b>“Usage Data”</b>).
                  </p>
                  <p class="text-slate-400">
                    This Usage Data may include information such as your
                    computer’s Internet Protocol address (e.g. IP address),
                    browser type, browser version, the pages of our Service that
                    you visit, the time and date of your visit, the time spent
                    on those pages, unique device identifiers and other
                    diagnostic data.
                  </p>
                  <p class="text-slate-400">
                    When you access Service with a device, this Usage Data may
                    include information such as the type of device you use, your
                    device unique ID, the IP address of your device, your device
                    operating system, the type of Internet browser you use,
                    unique device identifiers and other diagnostic data.
                  </p>
                  <p class="text-slate-400">
                    <b>Location Data</b>
                  </p>
                  <p class="text-slate-400">
                    We may use and store information about your location if you
                    give us permission to do so (<b>“Location Data”</b>). We use
                    this data to provide features of our Service, to improve and
                    customize our Service.
                  </p>
                  <p class="text-slate-400">
                    You can enable or disable location services when you use our
                    Service at any time by way of your device settings.
                  </p>
                  <p class="text-slate-400">
                    <b>Tracking Cookies Data</b>
                  </p>
                  <p class="text-slate-400">
                    We use cookies and similar tracking technologies to track
                    the activity on our Service and we hold certain information.
                  </p>
                  <p class="text-slate-400">
                    Cookies are files with a small amount of data which may
                    include an anonymous unique identifier. Cookies are sent to
                    your browser from a website and stored on your device. Other
                    tracking technologies are also used such as beacons, tags
                    and scripts to collect and track information and to improve
                    and analyze our Service.
                  </p>
                  <p class="text-slate-400">
                    You can instruct your browser to refuse all cookies or to
                    indicate when a cookie is being sent. However, if you do not
                    accept cookies, you may not be able to use some portions of
                    our Service.
                  </p>
                  <p class="text-slate-400">Examples of Cookies we use:</p>
                  <p class="text-slate-400">
                    0.1. <b>Session Cookies:</b> We use Session Cookies to
                    operate our Service.
                  </p>
                  <p class="text-slate-400">
                    0.2. <b>Preference Cookies:</b> We use Preference Cookies to
                    remember your preferences and various settings.
                  </p>
                  <p class="text-slate-400">
                    0.3. <b>Security Cookies:</b> We use Security Cookies for
                    security purposes.
                  </p>
                  <p class="text-slate-400">
                    0.4. <b>Advertising Cookies:</b> Advertising Cookies are
                    used to serve you with advertisements that may be relevant
                    to you and your interests.
                  </p>
                  <p class="text-slate-400">
                    <b>Other Data</b>
                  </p>
                  <p class="text-slate-400">
                    While using our Service, we may also collect the following
                    information: father's name, mother's name, alternative
                    number, shop/business full address, sex, age, date of birth,
                    place of birth, passport details, pan card details, bank
                    account details, bank passbook, cancel cheque, aadhaar card
                    details, shop image, selfie image, passport size image,
                    identity proof, address proof, shop/business proof,
                    citizenship, registration at place of residence and actual
                    address, telephone number (work, mobile), details of
                    documents on education, qualification, professional
                    training, employment agreements, NDA agreements, information
                    on bonuses and compensation, information on marital status,
                    family members, social security (or other taxpayer
                    identification) number, office location and other data
                  </p>
                  <h5 class="text-xl font-semibold mb-4 mt-8">Use of Data :</h5>
                  <p class="text-slate-400">
                    Recharge POS uses the collected data for various purposes:
                  </p>
                  <p class="text-slate-400">
                    0.1. to provide and maintain our Service;
                  </p>
                  <p class="text-slate-400">
                    0.2. to notify you about changes to our Service;
                  </p>
                  <p class="text-slate-400">
                    0.3. to allow you to participate in interactive features of
                    our Service when you choose to do so;
                  </p>
                  <p class="text-slate-400">
                    0.4. to provide customer support;
                  </p>
                  <p class="text-slate-400">
                    0.5. to gather analysis or valuable information so that we
                    can improve our Service;
                  </p>
                  <p class="text-slate-400">
                    0.6. to monitor the usage of our Service;
                  </p>
                  <p class="text-slate-400">
                    0.7. to detect, prevent and address error, fraud, criminal
                    activity, abuse of our services and technical issues;;
                  </p>
                  <p class="text-slate-400">
                    0.8. to fulfil any other purpose for which you provide it;
                  </p>
                  <p class="text-slate-400">
                    0.9. to carry out our obligations and enforce our rights
                    arising from any contracts entered into between you and us,
                    including for billing and collection;
                  </p>
                  <p class="text-slate-400">
                    0.10. to provide you with notices about your account and/or
                    subscription, including expiration and renewal notices,
                    email-instructions, etc.;
                  </p>
                  <p class="text-slate-400">
                    0.11. to provide you with news, special offers and general
                    information about other goods, services and events which we
                    offer that are similar to those that you have already
                    purchased or enquired about unless you have opted not to
                    receive such information;
                  </p>
                  <p class="text-slate-400">
                    0.12. in any other way we may describe when you provide the
                    information;
                  </p>
                  <p class="text-slate-400">
                    0.13. for any other purpose with your consent.
                  </p>
                  <h5 class="text-xl font-semibold mb-4 mt-8">
                    Retention of Data :
                  </h5>
                  <p class="text-slate-400">
                    We will retain your Personal Data only for as long as is
                    necessary for the purposes set out in this Privacy Policy.
                    We will retain and use your Personal Data to the extent
                    necessary to comply with our legal obligations (for example,
                    if we are required to retain your data to comply with
                    applicable laws), resolve disputes, and enforce our legal
                    agreements and policies.
                  </p>
                  <p class="text-slate-400">
                    We will also retain Usage Data for internal analysis
                    purposes. Usage Data is generally retained for a shorter
                    period, except when this data is used to strengthen the
                    security or to improve the functionality of our Service, or
                    we are legally obligated to retain this data for longer time
                    periods.
                  </p>
                  <h5 class="text-xl font-semibold mb-4 mt-8">
                    Transfer of Data :
                  </h5>
                  <p class="text-slate-400">
                    Your information, including Personal Data, may be
                    transferred to – and maintained on – computers located
                    outside of your state, province, country or other
                    governmental jurisdiction where the data protection laws may
                    differ from those of your jurisdiction.
                  </p>
                  <p class="text-slate-400">
                    If you are located outside India and choose to provide
                    information to us, please note that we transfer the data,
                    including Personal Data, to India and process it there.
                  </p>
                  <p class="text-slate-400">
                    Your consent to this Privacy Policy followed by your
                    submission of such information represents your agreement to
                    that transfer.
                  </p>
                  <p class="text-slate-400">
                    Recharge POS will take all the steps reasonably necessary to
                    ensure that your data is treated securely and in accordance
                    with this Privacy Policy and no transfer of your Personal
                    Data will take place to an organisation or a country unless
                    there are adequate controls in place including the security
                    of your data and other personal information.
                  </p>
                  <h5 class="text-xl font-semibold mb-4 mt-8">
                    Disclosure of Data :
                  </h5>
                  <p class="text-slate-400">
                    We may disclose personal information that we collect, or you
                    provide:
                  </p>
                  <p class="text-slate-400">
                    0.1. <b>Business Transaction.</b>
                  </p>
                  <p class="text-slate-400">
                    If we or our subsidiaries are involved in a merger,
                    acquisition or asset sale, your Personal Data may be
                    transferred.
                  </p>
                  <p class="text-slate-400">
                    0.2.{" "}
                    <b>Other cases. We may disclose your information also:</b>
                  </p>
                  <p class="text-slate-400">
                    0.2.1. to our subsidiaries and affiliates;
                  </p>
                  <p class="text-slate-400">
                    0.2.2. to contractors, service providers, and other third
                    parties we use to support our business;
                  </p>
                  <p class="text-slate-400">
                    0.2.3. to fulfill the purpose for which you provide it;
                  </p>
                  <p class="text-slate-400">
                    0.2.4. for the purpose of including your company’s logo on
                    our website;
                  </p>
                  <p class="text-slate-400">
                    0.2.5. for any other purpose disclosed by us when you
                    provide the information;
                  </p>
                  <p class="text-slate-400">
                    0.2.6. with your consent in any other cases;
                  </p>
                  <p class="text-slate-400">
                    0.2.7. if we believe disclosure is necessary or appropriate
                    to protect the rights, property, or safety of the Company,
                    our customers, or others.
                  </p>
                  <h5 class="text-xl font-semibold mb-4 mt-8">
                    Security of Data :
                  </h5>
                  <p class="text-slate-400">
                    The security of your data is important to us but remember
                    that no method of transmission over the Internet or method
                    of electronic storage is 100% secure. While we strive to use
                    commercially acceptable means to protect your Personal Data,
                    we cannot guarantee its absolute security.
                  </p>
                  <h5 class="text-xl font-semibold mb-4 mt-8">
                    Account Deletion :
                  </h5>
                  <p class="text-slate-400">
                    At Recharge POS, we value your privacy and provide users
                    with the option to delete their accounts at any time.
                  </p>
                  <p class="text-slate-400">
                    0.1. You may request to delete your account by contacting
                    our support team at helpdesk@rechargeindia.app;
                  </p>
                  <p class="text-slate-400">
                    0.2. Once the account deletion request is submitted, your
                    account and all associated data will be permanently removed
                    from our system within 30 days;
                  </p>
                  <p class="text-slate-400">
                    0.3. Please note, this action is irreversible, and all your
                    transaction history, personal data, and account-related
                    information will be lost;
                  </p>
                  <p class="text-slate-400">
                    0.4. After deletion, you will no longer have access to our
                    services. However, if you encounter any issues or have
                    questions, you can still contact our support team for
                    assistance;
                  </p>
                  <h5 class="text-xl font-semibold mb-4 mt-8">
                    Service Providers :
                  </h5>
                  <p class="text-slate-400">
                    We may employ third party companies and individuals to
                    facilitate our Service (<b>“Service Providers”</b>), provide
                    Service on our behalf, perform Service-related services or
                    assist us in analysing how our Service is used.
                  </p>
                  <p class="text-slate-400">
                    These third parties have access to your Personal Data only
                    to perform these tasks on our behalf and are obligated not
                    to disclose or use it for any other purpose.
                  </p>
                  <h5 class="text-xl font-semibold mb-4 mt-8">Analytics :</h5>
                  <p class="text-slate-400">
                    We may use third-party Service Providers to monitor and
                    analyze the use of our Service.
                  </p>
                  <h5 class="text-xl font-semibold mb-4 mt-8">CI/CD tools :</h5>
                  <p class="text-slate-400">
                    We may use third-party Service Providers to automate the
                    development process of our Service.
                  </p>
                  <h5 class="text-xl font-semibold mb-4 mt-8">
                    Behavioral Remarketing :
                  </h5>
                  <p class="text-slate-400">
                    We may use remarketing services to advertise on third party
                    websites to you after you visited our Service. We and our
                    third-party vendors use cookies to inform, optimise and
                    serve ads based on your past visits to our Service.
                  </p>
                  <h5 class="text-xl font-semibold mb-4 mt-8">
                    Links to Other Sites :
                  </h5>
                  <p class="text-slate-400">
                    Our Service may contain links to other sites that are not
                    operated by us. If you click a third party link, you will be
                    directed to that third party’s site. We strongly advise you
                    to review the Privacy Policy of every site you visit.
                  </p>
                  <p class="text-slate-400">
                    We have no control over and assume no responsibility for the
                    content, privacy policies or practices of any third party
                    sites or services.
                  </p>
                  <h5 class="text-xl font-semibold mb-4 mt-8">
                    Children’s Privacy :
                  </h5>
                  <p class="text-slate-400">
                    Our Services are not intended for use by children under the
                    age of 18 (<b>“Child”</b> or <b>“Children”</b>).
                  </p>
                  <p class="text-slate-400">
                    We do not knowingly collect personally identifiable
                    information from Children under 18. If you become aware that
                    a Child has provided us with Personal Data, please contact
                    us. If we become aware that we have collected Personal Data
                    from Children without verification of parental consent, we
                    take steps to remove that information from our servers.
                  </p>
                  <h5 class="text-xl font-semibold mb-4 mt-8">
                    Changes to This Privacy Policy :
                  </h5>
                  <p class="text-slate-400">
                    We may update our Privacy Policy from time to time. We will
                    notify you of any changes by posting the new Privacy Policy
                    on this page.
                  </p>
                  <p class="text-slate-400">
                    We will let you know via email and/or a prominent notice on
                    our Service, prior to the change becoming effective and
                    update “effective date” at the top of this Privacy Policy.
                  </p>
                  <p class="text-slate-400">
                    You are advised to review this Privacy Policy periodically
                    for any changes. Changes to this Privacy Policy are
                    effective when they are posted on this page.
                  </p>
                  <h5 class="text-xl font-semibold mb-4 mt-8">Contact Us :</h5>
                  <p class="text-slate-400 mb-10">
                    <p>
                      If you have any questions about this Privacy Policy,
                      please contact us by email:{" "}
                      <b>helpdesk@rechargepos.com</b>.
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default RefundPolicy;
